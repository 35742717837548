import { CommonService } from 'src/app/services/common.service';
import { environment } from './../../environments/environment.prod';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, throwError } from 'rxjs';
import { APIKEYS } from '../modules/shared/apiKeys';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(private http: HttpClient, private commonServie: CommonService) {}
  BASE_URL = environment.baseUrl;
  ALTERNATE_BASE_URL = environment.alternateBaseUrl;

  getHttpOptions(body: any = {}, token = '') {
    let headers = new HttpHeaders()
      .set('Authorization', `Bearer${' ' + token}`)
      .set('Content-Type', 'application/json');
    return { body, headers };
  }

  login(payload: any) {
    return this.call(APIKEYS.login, this.getHttpOptions(payload));
  }

  registration(payload: any) {
    return this.call(APIKEYS.register, this.getHttpOptions(payload));
  }

  logOut() {
    const data = {};
    return this.call(
      APIKEYS.logout,
      this.getHttpOptions(data, this.getToken())
    );
  }

  getProfileDetails() {
    return this.call(
      APIKEYS.profileDetails,
      this.getHttpOptions({}, this.getToken())
    );
  }

  uploadProfileImage(payload: any) {
    let headers = new HttpHeaders().set(
      'Authorization',
      `Bearer${' ' + this.getToken()}`
    );
    const url =
      APIKEYS.uploadProfileImage.baseUrl + APIKEYS.uploadProfileImage.endPoint;
    return this.http.post(url, payload, { headers }).pipe(map((res) => res));
  }

  addAddress(payload: any) {
    return this.call(
      APIKEYS.addAddress,
      this.getHttpOptions(payload, this.getToken())
    );
  }
  deleteAddress(addressId: string) {
    return this.call(
      APIKEYS.deleteAddress,
      this.getHttpOptions({}, this.getToken()),
      addressId
    );
  }
  defaultAddress(addressId: string) {
    return this.call(
      APIKEYS.defaultAddress,
      this.getHttpOptions({}, this.getToken()),
      addressId
    );
  }
  updateProfile(payload: any) {
    return this.call(
      APIKEYS.updateProfile,
      this.getHttpOptions(payload, this.getToken())
    );
  }
  getSubscriptions() {
    return this.call(APIKEYS.getSubscriptions);
  }
  getLocation() {
    return this.call(APIKEYS.getLocation);
  }
  getFilteredLocation(payload: string) {
    const queryUrl = `?name=${payload}`;
    return this.call(
      APIKEYS.getFilteredLocation,
      this.getHttpOptions({}),
      '',
      queryUrl
    );
  }

  sendOtpCode(payload: any) {
    return this.call(APIKEYS.sendOtpCode, this.getHttpOptions(payload));
  }
  verifyOtp(payload: any) {
    return this.call(APIKEYS.verifyOtpCode, this.getHttpOptions(payload));
  }
  sendRegistrationOtpCode(payload: any) {
    return this.call(APIKEYS.sendRegistrationOtpCode, this.getHttpOptions(payload));
  }
  verifyRegistrationOtp(payload: any) {
    return this.call(APIKEYS.verifyRegistrationOtpCode, this.getHttpOptions(payload));
  }
  resetPassword(payload: any, otpToken: string) {
    return this.call(
      APIKEYS.resetPassword,
      this.getHttpOptions(payload, otpToken)
    );
  }
  getHomeSettings() {
    return this.call(APIKEYS.homePage);
  }
  getRoomList(payload: any, pageno: number, perPageItem: number = 10) {
    const queryUrl = `?page=${pageno}&limit=${perPageItem}`;
    return this.call(
      APIKEYS.roomSearch,
      this.getHttpOptions(payload),
      '',
      queryUrl
    );
  }
  getRoomSummery(payload: any) {
    return this.call(
      APIKEYS.restaurantSummary,
      this.getHttpOptions(payload, this.getToken())
    );
  }

  doPayment(payload: any) {
    return this.call(
      APIKEYS.payment,
      this.getHttpOptions(payload, this.getToken())
    );
  }

  getHotelDetails(
    hotelId: string,
    formDate: string,
    toDate: string,
    no_adults: number,
    no_of_childrens: number,
    no_of_rooms: number
  ) {
    const queryUrl = `/${hotelId}/from_date/${formDate}/to_date/${toDate}?no_of_rooms=${no_of_rooms}&no_adults=${no_adults}&no_of_childrens=${no_of_childrens}`;
    return this.call(
      APIKEYS.hotelSearchDetails,
      this.getHttpOptions({}),
      '',
      queryUrl
    );
  }
  getRestaurantList(
    searchedText: string = '',
    pageNo: number,
    location_id: string
  ) {
    const queryUrl = `?search=${searchedText}&searchJoin=and&limit=10&page=${pageNo}&orderBy=created_at&sortedBy=desc&location_id=${location_id}`;
    return this.call(
      APIKEYS.restaurantList,
      this.getHttpOptions({}),
      '',
      queryUrl
    );
  }
  getRestaurantDetails(restaurantId: string) {
    return this.call(
      APIKEYS.restaurantDetails,
      this.getHttpOptions({}),
      restaurantId
    );
  }

  getRestaurantMenuList(restaurantId: string) {
    return this.call(
      APIKEYS.foodMenuList,
      this.getHttpOptions({}),
      restaurantId
    );
  }

  getRestaurantProductsList(
    restaurantId: string,
    searchedText: string,
    pageNo: number,
    categoryId: string = '',
    perPageItem: number = 20
  ) {
    const queryUrl = `?search=${searchedText}&limit=${perPageItem}&page=${pageNo}&orderBy=created_at&sortedBy=desc&category_id=${categoryId}`;
    return this.call(
      APIKEYS.productListInRestaurant,
      this.getHttpOptions({}),
      restaurantId,
      queryUrl
    );
  }

  getTimelineMilestones() {
    return this.call(
      APIKEYS.timelineMilestones,
      this.getHttpOptions({}, this.getToken())
    );
  }

  getFoodPurchasesList(pageNo: number) {
    const queryUrl = `?limit=10&page=${pageNo}`;
    return this.call(
      APIKEYS.purchasesFoodOrderList,
      this.getHttpOptions({}, this.getToken()),
      '',
      queryUrl
    );
  }

  getFoodPurchasesOrderDetails(orderId: string) {
    return this.call(
      APIKEYS.purchasesFoodOrderDetails,
      this.getHttpOptions({}, this.getToken()),
      orderId
    );
  }

  getSubscriptionsPurchasesList(customerId: string, pageNo: number) {
    const queryUrl = `?page=${pageNo}&limit=10`;
    return this.call(
      APIKEYS.purchasesSubscriptionsList,
      this.getHttpOptions({}, this.getToken()),
      customerId,
      queryUrl
    );
  }
  getRoomPurchasesList(customerId: string, pageNo: number) {
    const queryUrl = `?page=${pageNo}&limit=10`;
    return this.call(
      APIKEYS.purchasesRoomsList,
      this.getHttpOptions({}, this.getToken()),
      customerId,
      queryUrl
    );
  }
  getRoomPurchasesDetails(orderId: string) {
    return this.call(
      APIKEYS.purchasesRoomDetails,
      this.getHttpOptions({}, this.getToken()),
      orderId
    );
  }
  getEventPurchasesList(pageNo: number) {
    const queryUrl = `?limit=10&page=${pageNo}`;
    return this.call(
      APIKEYS.purchasesEventOrderList,
      this.getHttpOptions({}, this.getToken()),
      '',
      queryUrl
    );
  }
  getEventPurchasesDetails(orderId: string) {
    const queryUrl = `${orderId}`;
    return this.call(
      APIKEYS.purchasesEventOrderDetails,
      this.getHttpOptions({}, this.getToken()),
      '',
      queryUrl
    );
  }
  getLoyaltiesList(pageNo: number, customerId: string) {
    const queryUrl = `?page=${pageNo}&limit=10&customer_id=${customerId}`;
    return this.call(
      APIKEYS.loyaltiesPointsList,
      this.getHttpOptions({}, this.getToken()),
      '',
      queryUrl
    );
  }

  foodOrderSummery(orderPayload: any) {
    return this.call(
      APIKEYS.foodOrderSummery,
      this.getHttpOptions(orderPayload, this.getToken())
    );
  }

  placeFoodOrder(orderPayload: any) {
    return this.call(
      APIKEYS.placeFoodOrder,
      this.getHttpOptions(orderPayload, this.getToken())
    );
  }

  paymentVerification(payload: any) {
    return this.call(
      APIKEYS.paymentVerification,
      this.getHttpOptions(payload, this.getToken())
    );
  }

  getEventVenue() {
    const querUrl = '?page=1&limit=10';
    return this.call(
      APIKEYS.getEventVenue,
      this.getHttpOptions({}, this.getToken()),
      '',
      querUrl
    );
  }

  getEventList(eventDate: string, venueId: string, pageNo: number) {
    const queryUrl = `?page=${pageNo}&limit=10&event_date=${eventDate}&event_venue_id=${venueId}`;
    return this.call(
      APIKEYS.getEventList,
      this.getHttpOptions({}),
      '',
      queryUrl
    );
  }
  getEventDetails(eventId: string) {
    const queryUrl = `/${eventId}`;
    return this.call(
      APIKEYS.getEventDetails,
      this.getHttpOptions({}, this.getToken()),
      '',
      queryUrl
    );
  }
  getEventAreaTypeForTableBooking(eventId: string, areaId: string) {
    const queryUrl = `/${eventId}/area/${areaId}/area-types`;
    return this.call(
      APIKEYS.getEventAreaTypeForTableBooking,
      this.getHttpOptions({}, this.getToken()),
      '',
      queryUrl
    );
  }
  getEventAvailableTableForBooking(
    eventId: string,
    areaId: string,
    areaTypeId: string
  ) {
    const queryUrl = `/${eventId}/area/${areaId}/area-type/${areaTypeId}/tables`;
    return this.call(
      APIKEYS.getEventAvailableTableForBooking,
      this.getHttpOptions({}, this.getToken()),
      '',
      queryUrl
    );
  }
  getEventSummery(payload: any) {
    return this.call(
      APIKEYS.getEventSummery,
      this.getHttpOptions(payload, this.getToken())
    );
  }
  getEventPaymentOrderId(payload: any) {
    return this.call(
      APIKEYS.getEventOrderIdForPayment,
      this.getHttpOptions(payload, this.getToken())
    );
  }
  getOrderListForFeedBack() {
    return this.call(
      APIKEYS.getOrderListForFeedBack,
      this.getHttpOptions({}, this.getToken())
    );
  }
  getAttributeListForFeedBack(payload: any) {
    return this.call(
      APIKEYS.getAttributeListForFeedBack,
      this.getHttpOptions(payload, this.getToken())
    );
  }
  sendFeedback(payload: any) {
    return this.call(
      APIKEYS.sendFeedback,
      this.getHttpOptions(payload, this.getToken())
    );
  }
  requestGuest(payload: any) {
    return this.call(
      APIKEYS.requestGuest,
      this.getHttpOptions(payload, this.getToken())
    );
  }

  getAutocompleteLocation(searchedText: any) {
    const queryUrl = `?input=${searchedText}`;
    return this.call(
      APIKEYS.getAutocompleteLocation,
      this.getHttpOptions({}, this.getToken()),
      '',
      queryUrl
    );
  }
  getLocationDetails(placeId: any) {
    const queryUrl = `?place_id=${placeId}`;
    return this.call(
      APIKEYS.getLocationDetails,
      this.getHttpOptions({}, this.getToken()),
      '',
      queryUrl
    );
  }

  getPaymentStatus(transactionId: any) {
    const queryUrl = `?merchant_transaction_id=${transactionId}`;
    return this.call(
      APIKEYS.getPaymentStatus,
      this.getHttpOptions({}, this.getToken()),
      '',
      queryUrl
    );
  }

  call(api: any, options?: any, queryParms?: string, searchedData?: string) {
    const baseUrl = api.baseUrl + api.endPoint;
    let url = queryParms ? baseUrl + '/' + queryParms : baseUrl;
    url = searchedData ? baseUrl + searchedData : url;
    url =
      queryParms && searchedData
        ? baseUrl + '/' + queryParms + searchedData
        : url;
    return this.http
      .request(api.method, url, options)
      .pipe(catchError(this.handleError));
  }

  private getToken() {
    return this.commonServie.getSessionToken();
  }
  private handleError(error: HttpErrorResponse) {
    console.log(error);
    if (error.status === 0) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, body was: `,
        error.error
      );
    }
    // Return an observable with a user-facing error message.
    const { code, message, status, subCode } = error.error;
    const errorData: any = new Error();
    errorData.status = status;
    errorData.type = status == 'Error' ? 'danger' : 'success';
    errorData.subCode = typeof subCode != 'undefined' ? subCode : '';
    errorData.message =
      code.toString()[0] == 4 ? message : 'Something went wrong';
    return throwError(() => errorData);
  }
}
